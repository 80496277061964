import React, { Fragment, useEffect } from "react";
import "../Sliders/Slider.css";
import "../HomePage/HomePage.css";

import ProductCard from "../ProductCard/ProductCard";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { SiLinode } from "react-icons/si";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Common_Event_Hit } from "../../Actions/Events";
import { useLocation } from 'react-router-dom';

const Slider = ({
  ProductRes,
  footerColor,
  id,
  widgetData,
  darkColor,
  lightColor,
  flagBg,
  screen,
  shimmer,
}) => {
  let navigate = useNavigate();
  const location = useLocation()
  const rightSroll = () => {
    let scrollStep = 400;
    let divId = id ? id : "products_slider";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;
    let cw = scrollDiv.scrollWidth;

    if (sl + scrollStep >= cw) {
      scrollDiv.scrollTo(cw, 0);
    } else {
      scrollDiv.scrollTo(sl + scrollStep, 0);
    }
  };
  const leftScroll = () => {
    let scrollStep = 400;
    let divId = id ? id : "products_slider";
    let scrollDiv = document.getElementById(divId);
    let sl = scrollDiv.scrollLeft;

    if (sl - scrollStep <= 0) {
      scrollDiv.scrollTo(0, 0);
    } else {
      scrollDiv.scrollTo(sl - scrollStep, 0);
    }
  };

  const autoSlideRight = (clear) => {
    let time;
    if (!clear) {
      for (let i = 0; i < 300; i++) {
        let scrollStep = 400;
        let divId = id ? id : "products_slider";
        let scrollDiv = document.getElementById(divId);
        let sl = scrollDiv.scrollLeft;
        let cw = scrollDiv.scrollWidth;

        if (sl + scrollStep >= cw) {
          scrollDiv.scrollTo(cw, 0);
        } else {
          scrollDiv.scrollTo(sl + scrollStep, 0);
        }
        i++;
      }
    }
  };
  const autoSlideLeft = (clear) => {
    let time;
    if (!clear) {
      for (let i = 0; i < 300; i++) {
        let scrollStep = 400;
        let divId = id ? id : "products_slider";
        let scrollDiv = document.getElementById(divId);
        let sl = scrollDiv.scrollLeft;

        if (sl - scrollStep <= 0) {
          scrollDiv.scrollTo(0, 0);
        } else {
          scrollDiv.scrollTo(sl - scrollStep, 0);
        }
        i++;
      }
    }
  };
  useEffect(() => {
  }, [ProductRes, shimmer]);
  return (
    <div>
      <div id={id ? id : "products_slider"} className="products_slide_view">
        {shimmer && (
          <>
            {[...Array(5)].map((_, index) => {
              return (
                <div className="padding_5" key={index}>
                  <ProductCard flagBg={flagBg} />
                </div>
              );
            })}
          </>
        )}

        {!shimmer &&
          ProductRes &&
          ProductRes?.medicinesResponsesList?.length > 0 &&
          ProductRes?.medicinesResponsesList?.map((medData, index) => {
            return (
              <div className="padding_5" key={index}>
                <ProductCard
                  position={index}
                  screen={screen}
                  medData={medData}
                  flagBg={flagBg}
                  widgetData={widgetData}
                />{" "}
              </div>
            );
          })}
      </div>
      <div
        className="center-con slider_footer"
      >
        <div className="center-con">
          <div className="round" onClick={leftScroll}>
            <div id="cta">
              <span className="arrowLeft primera next ">
                {" "}
                <FaChevronLeft
                  className="arrow_dark"
                  style={{ paddingRight: "5px", color: darkColor }}
                />
                &nbsp;&nbsp;&nbsp;
              </span>
              <span className="arrowLeft segunda next ">
                <FaChevronLeft
                  className="arrow_light"
                  style={{ color: lightColor }}
                />
              </span>
            </div>
          </div>
        </div>
        {widgetData ? (
          <div
            className="arrow_dark font_ubuntu see_all_btn cursor_pointer font_weight_md"
            style={{ padding: "0px 60px", marginTop: "-2px", color: darkColor }}
            onClick={async () => {
              Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "see_more_tap",
                pageId: widgetData.data.pageId,
                screen_name: localStorage.getItem("currentScreen"),
              });
              if(location.pathname.split('/')[1] === 'home')
              navigate(`/homeProducts/${widgetData && widgetData.data.pageId}`, {
                state: widgetData,
              });
              else  navigate(`/products/${widgetData && widgetData.data.pageId}`, {
                state: widgetData,
              });
            }}
          >
            SEE ALL DETAILS
          </div>
        ) : (
          <div
            className="arrow_dark font_ubuntu see_all_btn cursor_pointer font_weight_md"
            style={{ padding: "0px 60px", marginTop: "-2px", color: darkColor }}
          ></div>
        )}

        <div className="center-con">
          <div className="round" onClick={rightSroll}>
            <div id="cta">
              <span className="arrow primera next ">
                {" "}
                <FaChevronRight
                  className="arrow_light"
                  style={{ paddingRight: "5px", color: lightColor }}
                />
                &nbsp;&nbsp;&nbsp;
              </span>
              <span className="arrow segunda next ">
                <FaChevronRight
                  className="arrow_dark"
                  style={{ color: darkColor }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Slider);
