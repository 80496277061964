import React, { Fragment, useEffect, useState, useCallback, useContext } from "react";
import { Container, Spinner, Button } from "react-bootstrap";
import "../../App.css";
import "./SearchPage.css";
import Slider from "../Sliders/Slider";
import { getScreen } from "../../Actions/Screens";
import { refreshToken } from "../../Actions/Auth";
import { common_product_widget } from "../../Actions/Widgets";
import { useNavigate, useLocation } from "react-router";
import { getData } from "../../Actions/Product";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductCard2 from "../ProductCard/ProductCard2";
import NoData from "../../Images/png/no_data.png";
import { Common_Event_Hit } from "../../Actions/Events";
import OrderMessageModal from "../Modals/orderMessageModal";
import { usersData } from "../../App";

const SearchPage = ({ string }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const [widgetsData, setWidgetsData] = useState();
  const [recentSearches, setRecentSearches] = useState();
  const [topSearches, setTopSearches] = useState();
  const [trendingProducts, setTrendingProducts] = useState();
  const [orderAgain, setOrderAgain] = useState();
  const [recentshimmer, setRecentShimmer] = useState(false);
  const [trendingShimmer, setTrendingShimmer] = useState(false);
  const [topShimmer, setTopShimmer] = useState(false);
  const [searchString, setSearchString] = useState(null);
  const [page, setPage] = useState(0);
  const [searchProducts, setSearchProducts] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [ProductShimmer, setProductShimmer] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [orderMessage, setOrderMessage] = useState({
    show: false,
    content: "",
    header: "",
  });
  const {searchType } = useContext(usersData);
  const searchProduct = (keyup, string, pageNo) => {
    let params = {
      page: pageNo,
    };
    if (keyup) {
      setProductShimmer(true);
      setPage(0);
      params.page = 0;
      setSearchProducts([]);
    }
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "search_products",
      search_string: string,
      searchType:searchType
    });
   let searchKey=""
    if (searchType === "Composition") {
      searchKey="PRODUCTS_BY_SEARCH_COMPOSITION"
       params.searchString=string
    } else {
      searchKey="PRODUCTS_BY_SEARCH"
    }
    getData(searchKey, params, string)
      .then((res) => {
        let data = searchProducts;
         let medicinesList=[]
         let size=null
        if(searchType === "Composition" ){
             medicinesList=res.data.data.medicinesResponsesList
             size=res.data.data.size
        }else{
          medicinesList=res.data.medicinesResponsesList
          size=res.data.size
        }
        if (medicinesList.length > 0) {
          keyup && (data = []);
          data.push(...medicinesList);
          setSearchProducts(data);
          setDataLength(data.length);
        }
        setProductShimmer(false);
        if (size > 0 && data.length < 10) {
          fetchMoreData();
        } else if (
          size > 0 &&
          medicinesList.length === 0
        ) {
          fetchMoreData();
        }
        if (size === 0) {
          setHasMore(false);
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            searchProduct();
          });
        }
      });
  };
  let fetchMoreData = () => {
    setPage(+page + 1);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 400);
    };
  };

  const search = useCallback(debounce(searchProduct), []);

  const requestMedicine = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "request_medicine",
      request_medicine: searchString,
    });
    setRequestLoader(true);
    getData("REQUEST_PRODUCT", { medicineName: searchString })
      .then((res) => {
        setRequestLoader(false);
        setOrderMessage({
          show: true,
          content: "Medicine is requested",
          header: searchString,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            requestMedicine();
          });
        }
      });
  };
  useEffect(() => {
    // /home/scheme
    // regular_scheme_screen

    let userData = localStorage.getItem("userData");
    localStorage.setItem("currentScreen", "search_screen");
    localStorage.removeItem("currentScreenBannerData");

    if (userData) {
      const params = {
        retailerId: JSON.parse(userData).id,
        screenName: "search_screen",
      };

      let schemes_screen = () => setRecentShimmer(true);
      setTopShimmer(true);
      setTrendingShimmer(true);
      getScreen("HOME_SCREEN", params, {})
        .then((res) => {
          setWidgetsData(res.data.data);
          res.data.data.forEach((item) => {
            let getWidgetData = () => {
              let params;
              if (
                item.widgetName === "recent_widget" &&
                item.data.title === "Recent Search"
              ) {
                params = {
                  size: 6,
                };
              } else if (
                item.widgetName === "recent_widget" &&
                item.data.title === "Top Searches"
              ) {
                params = {
                  size: 6,
                };
              } else if (
                item.widgetName === "product_list_widget" &&
                item.data.title === "Trending Products"
              ) {
                params = {
                  size: 20,
                  screenName: "searchPage",
                };
              } else if (
                item.widgetName === "product_list_widget" &&
                item.data.title === "Order Again"
              ) {
                params = {
                  size: 6,
                };
              }

              let api = common_product_widget(item.data.apiEndPoint, params);
              api
                .then((res) => {
                  if (
                    item.widgetName === "recent_widget" &&
                    item.data.title === "Recent Search"
                  ) {
                    // clean response for any null items
                    // res.data.data.forEach((item, index) => {
                    //   if (item === null) delete res.data.data[index];
                    // });
                    setRecentSearches(res.data.data);
                    setRecentShimmer(false);
                  } else if (
                    item.widgetName === "recent_widget" &&
                    item.data.title === "Top Searches"
                  ) {
                    // clean response for any null items
                    // res.data.data.forEach((item, index) => {
                    //   if (item === null) delete res.data.data[index];
                    // });
                    setTopSearches(res.data.data);
                    setTopShimmer(false);
                  } else if (
                    item.widgetName === "product_list_widget" &&
                    item.data.title === "Trending Products"
                  ) {
                    setTrendingProducts(res.data.data);
                    setTrendingShimmer(false);
                  } else if (
                    item.widgetName === "product_list_widget" &&
                    item.data.title === "Order Again"
                  ) {
                    setOrderAgain(res.data.data);
                    // let shimmerObj=shimmer;
                    // shimmerObj.orderAgain=false;
                    // setShimmer(shimmerObj)
                  }
                })
                .catch((error) => {
                  if (error.response.status === 401) {
                    refreshToken().then((res) => {
                      getWidgetData();
                    });
                  }
                });
            };

            return getWidgetData();
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              schemes_screen();
            });
          }
        });
      schemes_screen();
    } else {
      navigate("/home");
    }
  }, []);

  useEffect(() => {}, [
    trendingProducts,
    orderAgain,
    topSearches,
    recentSearches,
  ]);

  useEffect(() => {
    let userData = localStorage.getItem("userData");

    if (userData) {
      let string = location.pathname.split("/")[2].split("%20").join(" ");
      if (string.length > 0) {
        setProductShimmer(true);
        setSearchProducts([]);
        setSearchString(string);
        setHasMore(true);
        search(true, string, page);
      } else {
        setSearchString(null);
      }
    } else {
      navigate("/home");
    }
  }, [location,searchType]);

  useEffect(() => {
    let userData = localStorage.getItem("userData");

    if (userData) {
      let string = location.pathname.split("/")[2].split("%20").join(" ");
      if (string.length > 0) {
        setSearchString(string);
        searchProduct(false, string, page);
      } else {
        setSearchString(null);
      }
    } else {
      navigate("/home");
    }
  }, [page]);

  return (
    <Fragment>
      <OrderMessageModal
        show={orderMessage.show}
        content={orderMessage.content}
        header={orderMessage.header}
        onHide={() => {
          setOrderMessage({ show: false, content: "", header: "" });
        }}
      />
      {!searchString && (
        <Container fluid className="searchPage">
          <div style={{ paddingTop: "62px" }}></div>

          {topShimmer && (
            <Container fluid className="top_searches">
              <p className="font_weight_md heading font_ubuntu color_ice">
                TOP SEARCHES
              </p>
              <div className="top_searches_content">
                {[...Array(5)].map((_, index) => (
                  <div
                    className="toasst color_secondary animateShimmer2"
                    key={index}
                  >
                    Loading...
                  </div>
                ))}
              </div>
            </Container>
          )}

          {!topShimmer && topSearches && topSearches.length > 0 && (
            <Container fluid className="top_searches">
              <p className="font_weight_md heading font_ubuntu color_ice">
                TOP SEARCHES
              </p>
              <div className="top_searches_content">
                {topSearches.map(
                  (item) =>
                    item !== null && (
                      <div
                        className="toasst color_secondary"
                        onClick={() => navigate(`/search/${item}`)}
                      >
                        {item}
                      </div>
                    )
                )}
              </div>
            </Container>
          )}
          {recentshimmer && (
            <Container fluid className="top_searches">
              <p className="font_weight_md heading font_ubuntu color_ice">
                RECENT SEARCHES
              </p>
              <div className="top_searches_content">
                {[...Array(5)].map((_, index) => {
                  return (
                    <div
                      className="toasst color_secondary animateShimmer2"
                      key={index}
                    >
                      Loading...
                    </div>
                  );
                })}
              </div>
            </Container>
          )}
          {recentSearches && recentSearches.length > 0 && (
            <Container fluid className="recent_searches">
              <p className="font_weight_md heading font_ubuntu color_ice">
                RECENT SEARCHES
              </p>
              <div className="top_searches_content">
                {recentSearches.map(
                  (item) =>
                    item !== null && (
                      <div
                        className="toasst color_secondary"
                        onClick={() => {
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "recent_tap",
                            recent_text: item,
                          });
                          navigate(`/search/${item}`);
                        }}
                      >
                        {item}
                      </div>
                    )
                )}
              </div>
            </Container>
          )}

          {trendingShimmer && (
            <Container fluid className="trending_products">
              <p className="font_weight_md heading font_ubuntu color_ice">
                TRENDING PRODUCTS
              </p>

              <Slider
                screen={"search_screen"}
                shimmer={trendingShimmer}
                ProductRes={trendingProducts}
                footerColor={"#50409700"}
                id={"searchTrending"}
                darkColor={"#ffffff"}
                lightColor={"rgba(255, 255, 255, 0.582)"}
              />
            </Container>
          )}

          {!trendingShimmer &&
            trendingProducts &&
            trendingProducts.medicinesResponsesList.length > 0 && (
              <Container fluid className="trending_products">
                <p className="font_weight_md heading font_ubuntu color_ice">
                  TRENDING PRODUCTS
                </p>

                <Slider
                  screen={"search_screen"}
                  shimmer={trendingShimmer}
                  ProductRes={trendingProducts}
                  footerColor={"#50409700"}
                  id={"searchTrending"}
                  darkColor={"#ffffff"}
                  lightColor={"rgba(255, 255, 255, 0.582)"}
                />
              </Container>
            )}
          {/* {
      &&  <Container fluid className="trending_products">
     <p className="font_weight_md heading font_ubuntu color_ice">OrderAgain</p>

     <Slider  shimmer={shimmer.orderAgain}
              ProductRes={trendingProducts} footerColor={'#50409700'} id={"covidEssentials"} darkColor={"#ffffff"} lightColor={"rgba(255, 255, 255, 0.582)"}/>

     </Container>
   } */}

          {orderAgain && orderAgain.medicinesResponsesList.length > 0 && (
            <Container fluid className="trending_products">
              <p className="font_weight_md heading font_ubuntu color_ice">
                Order Again
              </p>

              <Slider
                screen={"search_screen"}
                shimmer={false}
                ProductRes={orderAgain}
                footerColor={"#50409700"}
                id={"searchOrderAgain"}
                darkColor={"#ffffff"}
                lightColor={"rgba(255, 255, 255, 0.582)"}
              />
            </Container>
          )}
        </Container>
      )}

      {!ProductShimmer &&
        searchString &&
        searchString.length > 0 &&
        searchProducts &&
        searchProducts.length > 0 && (
          <Fragment>
            {/* <div className="search-autocomplete-container">
              {searchProducts.map((item, index) => {
                return (
                  <div className={activeACItem === index ? "search-autocomplete-list-item search-autocomplete-list-item-active" : 'search-autocomplete-list-item'} key={index}>{item?.name}</div>
                )
              })}

            </div> */}
            <div id="scrollableDiv" className="productList bg_dark_primary">
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollableTarget="scrollableDiv"
                scrollThreshold="90%"
                className="infiniteScroll"
                loader={
                  <Spinner
                    animation="border"
                    className="color_secondary spinner"
                    size="lg"
                  />
                }
              >
                {searchProducts.length > 0 &&
                  searchProducts.map((med, index) => (
                    <ProductCard2
                      position={index}
                      screen={"search_screen"}
                      key={index}
                      medData={med}
                    />
                  ))}
              </InfiniteScroll>
            </div>
          </Fragment>
        )}
      {!ProductShimmer &&
        searchProducts &&
        searchProducts.length === 0 &&
        searchString && (
          <div id="scrollableDiv" className="productList bg_dark_primary">
            <div className="no_data_parent">
              <img src={NoData} alt="No Data" />
              <br /> <br />
              <p className="font_ubuntu font_16 color_white font_weight_md">
                No Products Found !
              </p>
              <Button
                className="bg_secondary border_none font_ubuntu font_weight_md"
                onClick={requestMedicine}
              >
                {requestLoader ? (
                  <Spinner
                    animation="border"
                    className="color_primary"
                    size="sm"
                  />
                ) : (
                  "Request Medicine"
                )}
              </Button>
            </div>
          </div>
        )}
      {ProductShimmer && (
        <div
          className="productList bg_dark_primary"
          style={{ paddingTop: "10rem" }}
        >
          {[...Array(4)].map((_, index) => (
            <ProductCard2 shimmer={ProductShimmer} key={index} />
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default React.memo(SearchPage);
