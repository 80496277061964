import { Modal } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import "./dialog.css";
import { Common_Event_Hit } from "../../Actions/Events";
import { useNavigate } from "react-router";
import { getLedgerPaymentViews } from "../../Actions/Ledger";
import { usersData } from "../../App";
import { GiCancel } from "react-icons/gi";
const LedgerPaymentMappingView = (props) => {
  const { userData, setErrorHandler } = useContext(usersData);
  const [data, setData] = useState(null);
  const getData = () => {
    getLedgerPaymentViews({ invoiceNumber: props.invoiceNumber })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "ledger_payment_mapping_view",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getData();
          },
        });
      });
  };

  const close = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "dialog_close",
      dialog_name: "ledger_payment_mapping_view",
    });

    props.onHide();
  };

  useEffect(() => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "dialog_open",
      dialog_name: "ledger_payment_mapping_view",
    });
    props.invoiceNumber && getData();
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="ledger_payment_view_modal"
    >
      <div>
        <div className="display_flex space_btw payment_view_header">
          <h6>Payment Receipts - {props.invoiceNumber}</h6>
          <GiCancel className="cursor_pointer" onClick={close} />
        </div>

        <table className="payment_view_table" width={"100%"}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Voucher Type</th>
              <th>Mode</th>
              <th>Voucher Detail</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {!data && (
              <tr>
                <td colSpan={5} className="text_center">
                  Loading...
                </td>
              </tr>
            )}
            {data?.map((item) => {
              return (
                <tr>
                  <td>{item.date}</td>
                  <td>{item.voucherType}</td>
                  <td>{item.mode}</td>
                  <td>{item.voucherDetail}</td>
                  <td>₹ {item.amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default LedgerPaymentMappingView;
