import { Modal, Button, NavItem } from "react-bootstrap";
import React, { Fragment } from "react";
import '../../App.css';
import './dialog.css'
import Success from '../../Images/gifs/success.gif'
import { useNavigate } from "react-router";

const SuccessModal = (props) => {
  let navigate=useNavigate()
    return (
        <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="del_modal"
      show={props.show}
    >
      {/* <Modal.Header> */}
        <Modal.Body id="contained-modal-title-vcenter" className="padding_10 text_center" style={{margin:'0px auto'}}>
        <img src={Success} className="success_modal_svg"/>
        <br></br>
        <p className="font_ubuntu color_primary font_weight_md font_18">
           {props.content}
        </p>


        <div className="text_center">
            <Button
              style={{borderRadius: '3rem'}}
              className="bg_white width_100 brdr_none bg_secondary go_to_home  color_white font_weight_md"
              onClick={()=>navigate('/home')}
            >
              GO TO HOME
            </Button>
          </div>
        </Modal.Body>
      {/* </Modal.Header> */}
      {/* <Modal.Body>
       
      </Modal.Body> */}
     
    </Modal>
    )
}

export default SuccessModal
