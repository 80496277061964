import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "../../App.css";
import "./dialog.css";
import { Common_Event_Hit } from "../../Actions/Events";
import { useNavigate } from "react-router";
import { getPastOrderData } from "../../Actions/Ledger";
import { usersData } from "../../App";
import { GiCancel } from "react-icons/gi";
import OrderCard from "../MyAccount/OrderCard";
import { Digital_Payment_Actions } from "../../Actions/DigitalPayment";
import InfoModal from "./InfoModal";
import { DigitalPaymentModal } from "./DigitalPaymentModal";

const PastOrderCardView = (props) => {
  const { userData, setErrorHandler } = useContext(usersData);
  const [data, setData] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectAllOrders, setSelectAllOrders] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [activePaymentModes, setActivePaymentModes] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState(null);
  const [resetPastOrder, setResetPastOrder] = useState(false);
  const getData = () => {
    let body = {
      orderRetrieveIdList: props.id,
    };
    let params = {
      retailerCode: userData.retailerCode,
    };
    getPastOrderData(params, body)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "past_order_card_view",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getData();
          },
        });
      });
  };

  const close = () => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "dialog_close",
      dialog_name: "past_order_card_view",
    });

    props.onHide();
  };

  const orderSelect = (event, inv, selectAll = false) => {
    if (event.target.checked) {
      if (selectAll) {
        setSelectAllOrders(true);
        let selectedInv = [];
        data.forEach((order) => {
          if (order.onlinePaymentEnabled) {
            selectedInv.push(order.invoiceNumber);
          }
        });
        setSelectedOrders(selectedInv);
      } else {
        let orders = [...selectedOrders];
        orders.push(inv);
        setSelectedOrders(orders);
      }
    } else {
      if (selectAll) {
        setSelectedOrders([]);
        setSelectAllOrders(false);
      } else {
        let orders = [...selectedOrders];
        let index = orders.indexOf(inv);
        orders.splice(index, 1);
        setSelectedOrders(orders);
      }
    }
  };
  // Get active payment modes & status
  const getActivePaymentModes = () => {
    const params = {
      source: "WEB",
    };
    let body = {
      invoiceNumber: selectedOrders,
    };
    Digital_Payment_Actions("ACTIVE_PAYMENT_MODES", params, body)
      .then((res) => {
        if (res.data.data.status === "ERROR") {
          setInfoMsg(res.data.data.message);
          setShowInfo(true);
        } else {
          setActivePaymentModes(res.data.data);
          document.body.className = "overflow-hidden";
          setOpenPayment(!openPayment);
        }

        document.body.className = "overflow-hidden";
      })
      .catch((error) => {
        setErrorHandler({
          show: true,
          screen: "past_orders_screen",
          errorList: [{ error: error, code: "" }],
          fn: function () {
            getActivePaymentModes();
          },
        });
      });
  };

  // Handle Digital Payment Modal
  const handleDigitalPayment = (resetData) => {
    if (!openPayment) {
      getActivePaymentModes();
    } else {
      document.body.className = "";
      setOpenPayment(!openPayment);
      if (resetData) {
        props.refresh();
      }
    }
  };

  const handleInfoReset = () => {
    setShowInfo(false);
    if (resetPastOrder) {
      setResetPastOrder(false);
      props.refresh();
    }
  };
  useEffect(() => {
    Common_Event_Hit("DYNAMIC_EVENT", {
      eventName: "dialog_open",
      dialog_name: "past_order_card_view",
    });
    props.id && getData();
  }, []);

  useEffect(()=>{
   data?.length>0 && orderSelect({target:{checked:true}}, "", true)
  },[data])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="past_order_card_view"
    >
      <InfoModal
        show={showInfo}
        handleInfoReset={handleInfoReset}
        message={infoMsg}
      />
      {openPayment && (
        <DigitalPaymentModal
          show={openPayment}
          invoiceNumbers={selectedOrders}
          handleDigitalPayment={handleDigitalPayment}
          activePaymentModes={activePaymentModes}
          setInfoMsg={setInfoMsg}
          setShowInfo={setShowInfo}
          setResetPastOrder={setResetPastOrder}
        />
      )}
      <div
        className="display_flex space_btw payment_view_header"
        style={{ padding: "1% 2%" }}
      >
        <h6>
          <b>Pending Invoices</b>
        </h6>
        <GiCancel className="cursor_pointer" onClick={close} />
      </div>
      {!data && <div className="text_center">Loading...</div>}
      {data && (
        <div className="past_order_card_view_body">
          {data?.length>1 && selectedOrders?.length > 0 && (
            <div className="text_center bulkPaymentDiv space_btw">
              <div>
                <input
                  type="checkbox"
                  className="cart_check"
                  id="payBulkBtnRef"
                  checked={selectAllOrders}
                  onChange={(e) => orderSelect(e, "", true)}
                />
                <label for="cart_select_all" className="cursor_pointer">
                  &nbsp;&nbsp;Select All
                </label>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <label>
                  (Total <b>{selectedOrders.length}</b> orders selected)
                </label>
              </div>

              <Button className="bulkPay_btn" onClick={handleDigitalPayment}>
                Bulk Payment
              </Button>
            </div>
          )}
          {data?.map((order, key) => {
            return (
              <div className="order_card_outer_div width_100">
                {data?.length > 1 && order?.onlinePaymentEnabled && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-bottom-${key}`}>
                        <strong>Select orders for bulk payments</strong>
                      </Tooltip>
                    }
                  >
                    <input
                      type="checkbox"
                      className="cart_check"
                      checked={selectedOrders.includes(order.invoiceNumber)}
                      onChange={(e) => orderSelect(e, order.invoiceNumber)}
                    />
                  </OverlayTrigger>
                )}

                <OrderCard
                  medData={order}
                  refresh={props.refresh}
                  filter={"past"}
                  resetOrderData={props.refresh}
                />
              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
};

export default PastOrderCardView;
