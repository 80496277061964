import React, { useEffect } from "react";
import "./AlertModal.css";
import {BiLinkExternal} from "react-icons/bi"
import { useNavigate } from "react-router";
import { Common_Event_Hit } from "../../Actions/Events";

const CreditDueAlert = ({ show,data,onHide }) => {
let navigate=useNavigate()
    const close=()=>{
        Common_Event_Hit("DYNAMIC_EVENT", {
            eventName: "dialog_close",
            dialog_name: "credit_overdue_alert",
          });

        onHide()  
    }

    const gotoDues=()=>{
        onHide()
       navigate('/account/overDueOrders')
    }
    useEffect(() => {
        Common_Event_Hit("DYNAMIC_EVENT", {
          eventName: "dialog_open",
          dialog_name: "credit_overdue_alert",
        });
      }, []);

  return (
    <>
      {show && (
        <div className="alertCntr">
          <div className="alertModal">
            <div className="alert_icon alert_icon_secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="feather feather-alert-triangle"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z" />
                <line x1="12" y1="9" x2="12" y2="13" />
                <line x1="12" y1="17" x2="12.01" y2="17" />
              </svg>
            </div>
            <div className="alert_title color_primary">
                {data?.error?.length && data?.error[0]?.title?.toUpperCase()}
              </div>

            <div className="alert_header">
            
               
              <div className="alert_message text_center" style={{maxWidth:'70%',margin:'0% auto'}}>
                {data?.error?.length && data?.error[0]?.message}
              </div>
            </div>

            <div className="alertActions">
              <button className="alert_cancel_btn" onClick={close}>
                Cancel
              </button>
              <button
                className=" alert_confirm_btn alert_confirm_btn_secondary" style={{width:'fit-content'}}
               onClick={gotoDues}
              > 
                Clear&nbsp;Dues <BiLinkExternal/>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreditDueAlert;
