import axios from "axios";
import { variables, Moengage } from "../environments";
import mixpanel from "mixpanel-browser";

mixpanel.init(variables.mixPanelKey, { debug: true });

let config = {
  headers: {
    "Content-Type": "application/json",
    source: "pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const ADMIN_URL = variables.ADMIN_URL;
let userAgent = navigator.userAgent;
let browserName;
let onBehalfStatus = false;
if (userAgent.match(/chrome|chromium|crios/i)) {
  browserName = "chrome";
} else if (userAgent.match(/firefox|fxios/i)) {
  browserName = "firefox";
} else if (userAgent.match(/safari/i)) {
  browserName = "safari";
} else if (userAgent.match(/opr\//i)) {
  browserName = "opera";
} else if (userAgent.match(/edg/i)) {
  browserName = "edge";
} else {
  browserName = navigator.userAgent;
}

let userData = localStorage.getItem("userData");
userData = JSON.parse(userData);
Moengage.add_first_name(userData?.shopName);
Moengage.add_email(userData?.emailId);
Moengage.add_mobile(userData?.mobileNo);

const getToken = async () => {
  let token;
  let authData = localStorage.getItem("userAuth");
  let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
  let userData = localStorage.getItem("userData");
  userData = JSON.parse(userData);

  if (authData && JSON.parse(authData).data.jwtToken) {
    token = JSON.parse(authData).data.jwtToken;
  }
  config.headers.Authorization = "Bearer " + token;

  if (onBehalfRetCode && onBehalfRetCode.length > 0 && onBehalfRetCode !== "undefined" && userData?.admin) {
    config.headers.adminRetailerCode = onBehalfRetCode;
    config.headers.source = "pharmacyWebOnbehalf";
    onBehalfStatus = true;
  }
  return true;
};

export const Event_Actions = async (type, params, body, moenage_body) => {
  const widgets = getToken().then(() => {
    switch (type) {
      case "EVENT":
        body?.eventProperties?.deviceName && (body.eventProperties.deviceName = browserName);
        onBehalfStatus && (body.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (moenage_body.source = "pharmacyWebOnbehalf");
        Moengage.track_event(body.eventName, moenage_body);
        mixpanel.track(body.eventName, moenage_body);
        return axios.post(`${ADMIN_URL}/home/app_events`, body, {
          params: params,
          headers: config.headers,
        });
    }
  });
  return widgets;
};

export const Common_Event_Hit = async (type, eventName) => {
  const widgets = getToken().then(() => {
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    switch (type) {
      case "COMMON_EVENT":
        let body = {
          deviceId: "",
          eventName: eventName,
          eventProperties: {
            screen_name: localStorage.getItem("currentScreen"),
            timestamp: new Date().getTime(),
            screens_visited: localStorage.getItem("screenCount"),
            session_number: localStorage.getItem("sessionCount"),
            enabled: userData?.enabled,
            deviceName: browserName,
            phoneNumber: userData?.mobileNo,
            locale: "en",
            userId: userData?.id,
          },
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };

        let MoengageBody = {
          deviceId: "",
          eventName: eventName,
          screen_name: localStorage.getItem("currentScreen"),
          timestamp: new Date().getTime(),
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData?.enabled,
          deviceName: browserName,
          locale: "en",
          mobileNo: userData?.mobileNo,
          phoneNumber: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };
        onBehalfStatus && (body.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (MoengageBody.source = "pharmacyWebOnbehalf");
        Moengage.track_event(eventName, MoengageBody);
        mixpanel.track(eventName, MoengageBody);
        return axios.post(`${ADMIN_URL}/home/app_events`, body, {
          params: {},
          headers: config.headers,
        });

      case "SESSION_END":
        let time = new Date().getTime();
        let sessionTime = time - localStorage.getItem("sessionStartTime");
        let body2 = {
          deviceId: "",
          eventName: "session_end",
          eventProperties: {
            timestamp: time,
            session_time: sessionTime,
            screens_visited: localStorage.getItem("screenCount"),
            session_number: localStorage.getItem("sessionCount"),
            enabled: userData.enabled,
            deviceName: browserName,
            phoneNumber: userData?.mobileNo,
            locale: "en",
            userId: userData?.id,
          },
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };

        let MoengageBody2 = {
          deviceId: "",
          eventName: "session_end",
          timestamp: time,
          session_time: sessionTime,
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData.enabled,
          deviceName: browserName,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };
        localStorage.removeItem("screenCount");
        localStorage.removeItem("currentScreen");
        localStorage.removeItem("currentScreenStartTime");

        onBehalfStatus && (body2.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (MoengageBody2.source = "pharmacyWebOnbehalf");
        Moengage.track_event("session_end", MoengageBody2);
        mixpanel.track("session_end", MoengageBody2);
        return axios.post(`${ADMIN_URL}/home/app_events`, body2, {
          params: {},
          headers: config.headers,
        });

      case "SCREEN_OPEN":
        let body3 = {
          deviceId: "",
          eventName: "screen_open",
          eventProperties: {
            timestamp: new Date().getTime(),
            screen_name: localStorage.getItem("currentScreen"),
            screens_visited: localStorage.getItem("screenCount"),
            session_number: localStorage.getItem("sessionCount"),
            enabled: userData?.enabled,
            deviceName: browserName,
            phoneNumber: userData?.mobileNo,
            locale: "en",
            userId: userData?.id,
          },
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };
        let MoengageBody3 = {
          deviceId: "",
          eventName: "screen_open",
          timestamp: new Date().getTime(),
          screen_name: localStorage.getItem("currentScreen"),
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData?.enabled,
          deviceName: browserName,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };

        localStorage.setItem("currentScreenStartTime", new Date().getTime());
        onBehalfStatus && (body3.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (MoengageBody3.source = "pharmacyWebOnbehalf");
        Moengage.track_event("screen_open", MoengageBody3);
        mixpanel.track("screen_open", MoengageBody3);
        return axios.post(`${ADMIN_URL}/home/app_events`, body3, {
          params: {},
          headers: config.headers,
        });

      case "SCREEN_CLOSE":
        let time_close = new Date().getTime();
        let sessionTime_close = time_close - localStorage.getItem("currentScreenStartTime");
        let bannerData = JSON.parse(localStorage.getItem("currentScreenBannerData"));
        const body4 = {
          deviceId: "",
          eventName: "screen_close",
          eventProperties: {
            timestamp: time_close,
            session_time: sessionTime_close,
            user_activity: {
              screen_name: localStorage.getItem("currentScreen"),
              source: onBehalfStatus ? "pharmacyWebOnbehalf" : "pharmacyWeb",
              locale: "en",
              banner_position: bannerData?.index,
              banner_file_name: bannerData?.data?.fileName,
            },
            screen_name: localStorage.getItem("currentScreen"),
            screens_visited: localStorage.getItem("screenCount"),
            session_number: localStorage.getItem("sessionCount"),
            enabled: userData?.enabled,
            deviceName: browserName,
            phoneNumber: userData?.mobileNo,
            locale: "en",
            userId: userData?.id,
          },
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };
        const Moengagebody4 = {
          deviceId: "",
          eventName: "screen_close",
          timestamp: time_close,
          session_time: sessionTime_close,
          screen_name: localStorage.getItem("currentScreen"),
          banner_position: bannerData?.index,
          banner_file_name: bannerData?.data?.fileName,
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData?.enabled,
          deviceName: browserName,
          phoneNumber: userData?.mobileNo,
          locale: "en",
          userId: userData?.id,
          mobileNo: userData?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };
        onBehalfStatus && (body4.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (Moengagebody4.source = "pharmacyWebOnbehalf");
        Moengage.track_event("screen_close", Moengagebody4);
        mixpanel.track("screen_close", Moengagebody4);
        return axios.post(`${ADMIN_URL}/home/app_events`, body4, {
          params: {},
          headers: config.headers,
        });

      case "CARD_TAP":
        let body_card_tap = {
          deviceId: "",
          eventName: eventName.eventName,
          eventProperties: {
            medicine_id: eventName.medicineId,
            page_id: eventName.pageId,
            medicine_position: eventName.position,
            screen_name: localStorage.getItem("currentScreen"),
            timestamp: new Date().getTime(),
            screens_visited: localStorage.getItem("screenCount"),
            session_number: localStorage.getItem("sessionCount"),
            enabled: userData.enabled,
            deviceName: browserName,
            phoneNumber: userData.mobileNo,
            locale: "en",
            userId: userData.id,
          },
          mobileNo: userData.mobileNo,
          source: "pharmacyWeb",
          userId: userData.id,
          version: variables.version,
        };

        let Moengage_card_tap = {
          deviceId: "",
          eventName: eventName.eventName,
          medicine_id: eventName.medicineId,
          page_id: eventName.pageId,
          medicine_position: eventName.position,
          screen_name: localStorage.getItem("currentScreen"),
          timestamp: new Date().getTime(),
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData.enabled,
          deviceName: browserName,
          phoneNumber: userData.mobileNo,
          locale: "en",
          mobileNo: userData.mobileNo,
          source: "pharmacyWeb",
          userId: userData.id,
          version: variables.version,
        };
        onBehalfStatus && (body_card_tap.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (Moengage_card_tap.source = "pharmacyWebOnbehalf");
        Moengage.track_event(eventName.eventName, Moengage_card_tap);
        mixpanel.track(eventName.eventName, Moengage_card_tap);
        return axios.post(`${ADMIN_URL}/home/app_events`, body_card_tap, {
          params: {},
          headers: config.headers,
        });

      case "DYNAMIC_EVENT":
        let PageBannerData = JSON.parse(localStorage.getItem("currentScreenBannerData"));
        let dynamic_event_body = {
          deviceId: "",
          eventName: eventName.eventName,
          eventProperties: {
            medicine_id: eventName.medicineId,
            is_checked: eventName.is_checked,
            dialog_name: eventName.dialog_name,
            order_id: eventName.orderId,
            manufacturer: eventName.manufacturer,
            medicine_name: eventName.medicineName,
            dealType:eventName?.dealType,
            section_id: eventName.sectionId,
            price: eventName.price,
            nav_item: eventName.navItem,
            recent_text: eventName.recent_text,
            current_count: eventName.count,
            error_message: eventName.error_message,
            payment_option: eventName.payment_option,
            page_id: eventName.pageId,
            number_of_products_in_cart: eventName.number_of_products,
            medicine_names: eventName.medicine_names,
            banner_rank: PageBannerData?.index,
            banner_id: PageBannerData?.data?.fileName,
            medicine_position: eventName?.position,
            search_string: eventName?.search_string,
            searchType:eventName?.searchType,
            screen_name:
              eventName?.screen_name || localStorage.getItem("currentScreen"),
            timestamp: new Date().getTime(),
            screens_visited: localStorage.getItem("screenCount"),
            session_number: localStorage.getItem("sessionCount"),
            enabled: userData?.enabled,
            deviceName: browserName,
            phoneNumber: userData?.mobileNo ? userData.mobileNo : eventName?.mobileNo,
            locale: "en",
            userId: userData?.id,
            latitude: eventName?.latitude,
            longitude: eventName?.longitude,
            expanded: eventName?.expanded,
            issue_type: eventName?.issue_type,
            expand_issue: eventName?.expand_issue,
            ticket_id: eventName?.ticket_id,
            message: eventName?.message,
            invoiceId: eventName?.invoiceId,
            creditNoteId: eventName?.creditNoteId,
            payment_amount: eventName?.eventName,
            invoice_number: eventName?.invoice_number,
            payment_link: eventName?.payment_link,
            amount: eventName?.amount,
          },
          mobileNo: userData?.mobileNo ? userData.mobileNo : eventName?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
        };

        let Moengage_dynamic_event_body = {
          deviceId: "",
          eventName: eventName.eventName,
          medicine_id: eventName.medicineId,
          is_checked: eventName.is_checked,
          dialog_name: eventName.dialog_name,
          order_id: eventName.orderId,
          manufacturer: eventName.manufacturer,
          medicine_name: eventName.medicineName,
          section_id: eventName.sectionId,
          price: eventName.price,
          nav_item: eventName.navItem,
          recent_text: eventName.recent_text,
          current_count: eventName.count,
          error_message: eventName.error_message,
          payment_option: eventName.payment_option,
          page_id: eventName.pageId,
          number_of_products_in_cart: eventName.number_of_products,
          medicine_names: eventName.medicine_names,
          banner_rank: PageBannerData?.index,
          banner_id: PageBannerData?.data?.fileName,
          medicine_position: eventName?.position,
          dealType:eventName?.dealType,
          search_string: eventName?.search_string,
          searchType:eventName?.searchType,
          screen_name:
            eventName?.screen_name || localStorage.getItem("currentScreen"),
          timestamp: new Date().getTime(),
          screens_visited: localStorage.getItem("screenCount"),
          session_number: localStorage.getItem("sessionCount"),
          enabled: userData?.enabled,
          deviceName: browserName,
          phoneNumber: userData?.mobileNo ? userData.mobileNo : eventName?.mobileNo,
          locale: "en",
          userId: userData?.id,
          latitude: eventName?.latitude,
          longitude: eventName?.longitude,
          expanded: eventName?.expanded,
          issue_type: eventName?.issue_type,
          expand_issue: eventName?.expand_issue,
          ticket_id: eventName?.ticket_id,
          mobileNo: userData?.mobileNo ? userData.mobileNo : eventName?.mobileNo,
          source: "pharmacyWeb",
          userId: userData?.id,
          version: variables.version,
          invoiceId: eventName?.invoiceId,
          creditNoteId: eventName?.creditNoteId,
          message: eventName?.message,
          payment_amount: eventName?.eventName,
          invoice_number: eventName?.invoice_number,
          payment_link: eventName?.payment_link,
          amount: eventName?.amount,
        };
        Object.keys(Moengage_dynamic_event_body).forEach((key) => Moengage_dynamic_event_body[key] === undefined && delete Moengage_dynamic_event_body[key]);
        onBehalfStatus && (dynamic_event_body.source = "pharmacyWebOnbehalf");
        onBehalfStatus && (Moengage_dynamic_event_body.source = "pharmacyWebOnbehalf");
        Moengage.track_event(eventName.eventName, Moengage_dynamic_event_body);
        mixpanel.track(eventName.eventName, Moengage_dynamic_event_body);
        return axios.post(`${ADMIN_URL}/home/app_events`, dynamic_event_body, {
          params: {},
          headers: config.headers,
        });
    }
  });
  return widgets;
};
