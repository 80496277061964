import React, { useState, useEffect, Fragment, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../ProductCard/ProductCard.css";
import "../../App.css";
import info from "../../Images/svg/info-i.svg";
import { Link } from "react-router-dom";
import { ImPlus, ImMinus } from "react-icons/im";
import ProductPtrDialog from "../Modals/ProductPtrDialog";
// import ProductModal from "../Modals/ProductModal";
import { GiAlarmClock } from "react-icons/gi";
import { useLocation, useNavigate } from "react-router";
import { cartDataContext, searchContext, usersData } from "../../App";
import { Cart_Actions } from "../../Actions/Cart";
import { refreshToken } from "../../Actions/Auth";
import ErrorModal from "../Modals/ErrorModal";
import Thumb from "../../Images/svg/Thumb.svg";
import { Common_Event_Hit } from "../../Actions/Events";

const ProductCard2 = ({ flagBg, medData, shimmer, screen, key, position }) => {
  let location = useLocation();
  const [quantity, setQuantity] = useState(0);
  const [productPtrShow, setProductPtrShow] = React.useState(false);
  const [multiplyFactorForOrderQuantity, setmMltiplyFactorForOrderQuantity] =
    useState(1);
  const [maxQtyError, setMaxQtyError] = useState(false);
  const [maxStockError, setMaxStockError] = useState(false);
  const [minQtyError, setMinQtyError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { cartData, setCartData } = useContext(cartDataContext);
  const { setShowMaintenance } = useContext(usersData);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const [timerProductTime, setTimerProductTime] = useState({
    endTime: 1,
    stopWatch: "00:00:00",
  });

  const { showRecent, setShowRecent } = useContext(searchContext);

  let navigate = useNavigate();
  const addQuantity = (e, initialHit) => {
    e.stopPropagation();
    console.log(quantity);
    if (medData.maxOrderQuantity > 0 && quantity < medData.maxOrderQuantity) {
      let updatedQuantity;
      if (
        multiplyFactorForOrderQuantity &&
        multiplyFactorForOrderQuantity > 1
      ) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity >= medData.minOrderQuantity) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity < medData.minOrderQuantity) {
        updatedQuantity = quantity + medData.minOrderQuantity;
      }

      if (updatedQuantity <= medData.availableQuantity) {
        addToCart(updatedQuantity);
      } else {
        setMaxStockError(true);
        setTimeout(() => {
          setMaxStockError(false);
        }, 2000);
      }
    } else if (medData.maxOrderQuantity === 0) {
      let updatedQuantity;
      if (
        multiplyFactorForOrderQuantity &&
        multiplyFactorForOrderQuantity > 1
      ) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity >= medData.minOrderQuantity) {
        updatedQuantity = quantity + multiplyFactorForOrderQuantity;
      } else if (quantity < medData.minOrderQuantity) {
        updatedQuantity = quantity + medData.minOrderQuantity;
      }
      if (updatedQuantity <= medData.availableQuantity) {
        addToCart(updatedQuantity);
      } else {
        setMaxStockError(true);
        setTimeout(() => {
          setMaxStockError(false);
        }, 2000);
      }
    }

    if (initialHit) {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "add_to_cart",
        pageId: location.pathname.split("/")[2],
        medicineId: medData.id,
        medicineName: medData.name,
        position: position,
        price: medData.effectivePrice.toFixed(2),
        count: quantity + multiplyFactorForOrderQuantity,
        dealType:medData?.dealType==='EXPIRY'?'EXPIRY':undefined,
      });
    } else {
      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "count_increment_tap",
        medicineId: medData.id,
        pageId: location.pathname.split("/")[2],
        medicineName: medData.name,
        position: position,
        price: medData.effectivePrice.toFixed(2),
        count: quantity + multiplyFactorForOrderQuantity,
        dealType:medData?.dealType==='EXPIRY'?'EXPIRY':undefined,
            });
    }

    if (
      quantity > 0 &&
      medData.maxOrderQuantity > 0 &&
      quantity >= medData.maxOrderQuantity
    ) {
      setMaxQtyError(true);
      setTimeout(() => {
        setMaxQtyError(false);
      }, 2000);
    }
  };
  const minusQuantity = (e) => {
    e.stopPropagation();
    if (quantity > 0 && quantity > medData.minOrderQuantity) {
      let updatedQuantity = quantity - multiplyFactorForOrderQuantity;
      updatedQuantity > 0 && addToCart(updatedQuantity);
      updatedQuantity === 0 && removeFromCart();

      Common_Event_Hit("DYNAMIC_EVENT", {
        eventName: "count_decrement_tap",
        pageId: location.pathname.split("/")[2],
        medicineId: medData.id,
        medicineName: medData.name,
        position: position,
        price: medData.effectivePrice.toFixed(2),
        count: updatedQuantity,
        dealType:medData?.dealType==='EXPIRY'?'EXPIRY':undefined,
            });
    }

    if (quantity <= medData.minOrderQuantity) {
      removeFromCart();
      setMinQtyError(true);
      setTimeout(() => {
        setMinQtyError(false);
      }, 1000);
    }
  };

  const addToCart = (qty) => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    let expiryMed = medData?.dealType==='EXPIRY';
    if (medData.deliverySlot) {
      setShowSpinner(true);
      const params = {};
      const body = {
        androidMap: {
          locale: "en",
          source: "pharmacyWeb",
          deviceId: null,
          appVersion: navigator.userAgent,
          deviceName: null,
          screen_name: screen,
          appVersionCode: "156",
        },
        medicineSection: expiryMed ? "EXPIRY" : undefined,
        medicineId: medData.medicineId,
        quantity: qty,
        retailerCode: data.retailerCode,
        retailerId: data.id,
      };
      Cart_Actions("ADD_TO_CART", params, body)
        .then((res) => {
          setQuantity(qty);
          setShowSpinner(false);
          setCartData(res.data.data);
        })
        .catch((error) => {
          setShowSpinner(false);
          if (error.response.status === 401) {
            refreshToken().then((res) => {
              addToCart(qty);
            });
          } else if (
            error.response.status === 502 ||
            error.response.status === 503
          ) {
            setShowMaintenance({ status: true, apiData: error?.response });
          } else if (error.response.status === 500) {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: "Oops something went wrong !",
              tryAgain: function () {
                addToCart(qty);
              },
            });
          } else {
            setErrorModal({
              show: true,
              apiData: error?.response,
              content: error?.response?.data?.error?.error
                ? error.response.data.error.error
                : "Something went wrong!",
              tryAgain: function () {
                addToCart(qty);
              },
            });
          }
        });
    } else {
      window.alert("You cannot add product to cart without Delivery Slot");
    }
  };

  const removeFromCart = () => {
    let data = localStorage.getItem("userData");
    data = JSON.parse(data);
    setShowSpinner(true);
    const params = {};
    const body = {
      androidMap: {},
      medicineId: medData.medicineId,
      retailerCode: data.retailerCode,
      retailerId: data.id,
    };
    Cart_Actions("REMOVE_FROM_CART", params, body)
      .then((res) => {
        setShowSpinner(false);
        setQuantity(0);
        setCartData(res.data.data);
      })
      .catch((error) => {
        setShowSpinner(false);
        if (error.response.status === 401) {
          refreshToken().then((res) => {
            removeFromCart();
          });
        } else if (
          error.response.status === 502 ||
          error.response.status === 503 ||
          error.response.status === 504
        ) {
          setShowMaintenance({ status: true, apiData: error?.response });
        } else if (error.response.status === 500) {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: "Oops something went wrong !",
            tryAgain: function () {
              removeFromCart();
            },
          });
        } else {
          setErrorModal({
            show: true,
            apiData: error?.response,
            content: error?.response?.data?.error?.error
              ? error.response.data.error.error
              : "Something went wrong!",
            tryAgain: function () {
              removeFromCart();
            },
          });
        }
      });
  };
  useEffect(() => {
    if (medData && medData.multiplyFactorForOrderQuantity > 0) {
      setmMltiplyFactorForOrderQuantity(medData.multiplyFactorForOrderQuantity);
    }
  }, []);

  useEffect(() => {
    //disable timer when search is active to prevent debouncing issues
    if (showRecent) return;
    let timer;
    if (medData?.dealEndTime) {
      if (currentTime < medData.dealEndTime) {
        timer = setInterval(() => {
          setCurrentTime(new Date().getTime());
        }, 1000);
        function msToTime(s) {
          var ms = s % 1000;
          s = (s - ms) / 1000;
          var secs = s % 60;
          s = (s - secs) / 60;
          var mins = s % 60;
          var hrs = (s - mins) / 60;

          if (mins < 10) {
            mins = "0" + mins;
          }
          if (secs < 10) {
            secs = "0" + secs;
          }
          if (hrs < 10) {
            hrs = "0" + hrs;
          }
          let obj = { ...timerProductTime };
          obj.stopWatch = hrs + ":" + mins + ":" + secs;
          setTimerProductTime(obj);
        }
        msToTime(medData.dealEndTime - currentTime);
      } else {
        return () => {
          clearInterval(timer);
        };
      }
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, medData, showRecent]);
  return (
    <Fragment>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />
      <ProductPtrDialog
        show={productPtrShow}
        medData={medData}
        onHide={() => setProductPtrShow(false)}
      />
      {/* <ProductModal
       medData={medData}
        show={productModalShow}
        onHide={() => setProductModalShow(false)}
      /> */}

      {shimmer && (
        <div
          className="productCard_parent  cursor_pointer"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.447)",
            border: "none",
          }}
        >
          <div style={{ position: "relative" }}></div>

          <Row>
            <div className="display_flex ">
              <div
                className="padding_10 product_img animateShimmer"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.447)" }}
              ></div>
              <div className="color_primary font_weight_md product_name"> </div>
            </div>
          </Row>
          <Row
            className=" border_radius_5 animateShimmer2 marign_top_10 "
            style={{ padding: "10px 0px", marginTop: "3%" }}
          ></Row>
          <Row>
            <div
              className="display_flex space_btw animateShimmer2"
              style={{ height: "20px", margin: "3% 5%", width: "90%" }}
            ></div>
          </Row>
        </div>
      )}

      {!shimmer && medData && (
        <Fragment>
          <div
            className="productCard_parent2 cursor_pointer"
            onClick={async (e) => {
              await Common_Event_Hit("CARD_TAP", {
                eventName: "card_tap",
                medicineId: medData.id,
                position: position,
              });
              if (medData?.dealType==='EXPIRY') {
                navigate(`/product/${medData.id}?type=SE`);
              }
               else {
                navigate(`/product/${medData.id}`);
              }
            }}
          >
            <div style={{ position: "relative" }}>
              {medData.cashDiscountInPercentage > 0 && (
                <div
                  className=" cd_flag color_white font_ubutu font_weight_lg"
                  style={{ backgroundColor: flagBg }}
                >
                  {medData.cashDiscountInPercentage} %{" "}
                  <span style={{ color: "rgba(255, 255, 255, 0.664)" }}>
                    {" "}
                    CD
                  </span>
                </div>
              )}
            </div>

            <Row>
              <div className="display_flex product_header">
                <img
                  className="product_img2"
                  src={
                    medData?.medicineImage?.lowResUrl
                      ? medData.medicineImage.lowResUrl
                      : Thumb
                  }
                  alt="product_img"
                />
                <div className="padding_10"></div>
                <div>
                  {medData.medicineSubSectionType === "FlashSale" ? (
                    <div className="flashSale_tag">
                      <GiAlarmClock
                        style={{ fontSize: "14px", marginTop: "-1px" }}
                      />{" "}
                      FLASH SALE
                    </div>
                  ) : medData.medicineSubSectionType === "DealOfTheDay" ? (
                    <div className="deal_tag color_primary">
                      <GiAlarmClock
                        style={{ fontSize: "14px", marginTop: "-1px" }}
                      />{" "}
                      DEAL OF THE DAY
                    </div>
                  ) : medData.medicineSubSectionType === "Special_Rate" ? (
                    <div className="special_tag color_white">SPECIAL DEAL</div>
                  ) : (
                    medData.medicineSubSectionType !== "REGULAR" && (
                      <div className="special_tag color_white">
                        {medData.medicineSubSectionType}
                      </div>
                    )
                  )}

                  {medData.multiplyFactorForOrderQuantity > 1 && (
                    <div className="multiplyFactor_tag bg_primary">
                      BUY IN MULTIPLES OF{" "}
                      {medData.multiplyFactorForOrderQuantity}
                    </div>
                  )}

                  <div className="color_primary font_weight_md product_name">
                    <p>
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            {medData.name}
                          </Tooltip>
                        }
                      >
                        <span>{medData.name}</span>
                      </OverlayTrigger>
                    </p>
                  </div>
                  <div className="manf_cntr">
                    <p className="manf_link color_secondary font_weight_md font_ubuntu">
                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            {medData.manufacturer}
                          </Tooltip>
                        }
                      >
                        <span>{medData.manufacturer}</span>
                      </OverlayTrigger>
                    </p>
                  </div>
                </div>
              </div>

              {(medData.medicineSubSectionType === "DealOfTheDay" ||
                medData.medicineSubSectionType === "FlashSale") && (
                <>
                  {medData?.dealEndTime && (
                    <div className="timer_tag">
                      <GiAlarmClock
                        style={{ fontSize: "14px", marginTop: "-1px" }}
                      />
                      <span className="font_12">
                        {timerProductTime.stopWatch}
                      </span>{" "}
                      <span className="font_12">hrs left</span>
                    </div>
                  )}
                </>
              )}
            </Row>
            <Row
              className=" border_radius_5 marign_top_10 "
              style={{ padding: "5px 0px" }}
            >
              <div className="display_flex space_btw">
                <div className="display_flex space_btw">
                  <div className="color_grey font_ubuntu font_12 font_weight_md body_inStock">
                    {" "}
                    In Stock <br />{" "}
                    <span style={{ color: "black" }}>
                      {medData.availableQuantity}
                    </span>{" "}
                  </div>
                  <div className="color_grey font_ubuntu font_12 font_weight_md body_inExpiry">
                    {" "}
                    Expiry <br />{" "}
                    <span style={{ color: "black" }}>
                      {" "}
                      {medData.expiryDate}
                    </span>{" "}
                  </div>
                </div>
                <div
                  className="font_ubuntu font_12 color_grey font_weight_md"
                  style={{ textAlign: "right" }}
                >
                  Delivery
                  <br />{" "}
                  <span className="font_ubuntu" style={{ color: "black" }}>
                    {medData.deliveryDate}, {medData.deliverySlot}
                  </span>
                </div>
              </div>
            </Row>
            {medData.scheme && medData.scheme.length > 0 && (
              <Row style={{ margin: "2px" }}>
                <span className="deals_section">
                  <span className="color_grey font_12">DEALS :</span>
                  {medData.scheme.map((x) => {
                    return <div className="deal_card color_primary">{x} </div>;
                  })}
                </span>
              </Row>
            )}

            <Row className="">
              <div className="display_flex space_btw price_section">
                <div className="display_flex price_data space_btw brdr_grey">
                  <div className="color_grey font_8">
                    {" "}
                    MRP <br />
                    <span className="font_10 color_black font_weight_500">
                      ₹{medData.mrp}
                    </span>{" "}
                  </div>
                  <div
                    className="product_margin text_center"
                    style={{ padding: "3px 2px", lineHeight: "10px" }}
                  >
                    <span className="font_weight_md text_center">
                      {Math.round(medData.retailerMargin)}%
                    </span>{" "}
                    <br />
                    <span style={{ fontSize: "8px" }}>MARGIN</span>
                  </div>

                  <div style={{ lineHeight: "10px", textAlign: "right" }}>
                    {/* {medData.ptr !== medData.effectivePrice && (
                      <span className="color_grey text_line_through font_10 font_weight_md">
                        ₹ {medData.ptr.toFixed(2)}
                      </span>
                    )} */}

                    <span className="color_primary_lt font_weight_md font_10">
                      PTR{" "}
                      <img
                        src={info}
                        className="ptrInfo"
                        onClick={(e) => {
                          e.stopPropagation();
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "info_tap",
                            medicineId: medData.id,
                          });
                          setProductPtrShow(true);
                        }}
                        alt="ptr_img"
                      />
                    </span>
                    <br />
                    <span className="color_primary font_10 font_weight_md">
                      ₹{" "}
                      {medData.ptr &&
                        medData.ptr.toFixed(2).toString().split(".")[0]}
                    </span>
                    <span className="color_primary" style={{ fontSize: "8px" }}>
                      .
                      {medData.ptr &&
                        medData.ptr.toFixed(2).toString().split(".")[1]}
                    </span>
                  </div>
                </div>

                <div className="display_flex">
                  <div className="display_flex space_btw">
                    {quantity < 1 && (
                      <Button
                        type="button"
                        className="product_add_btn pc2_add"
                        onClick={(e) => addQuantity(e, true)}
                      >
                        {showSpinner ? (
                          <Spinner
                            animation="border"
                            className="color_secondary spinner"
                            size="sm"
                            style={{ margin: "0px 5px" }}
                          />
                        ) : (
                          <>
                            <span>ADD</span> <ImPlus />
                          </>
                        )}
                      </Button>
                    )}
                    {quantity > 0 && (
                      <div className="display_flex space_btw">
                        <div
                          className="product_add_btn"
                          onClick={minusQuantity}
                        >
                          <ImMinus />
                        </div>

                        <div
                          className="qty font_ubuntu"
                          style={{ textAlign: "center" }}
                        >
                          {showSpinner ? (
                            <Spinner
                              animation="border"
                              className="color_secondary spinner"
                              size="sm"
                              style={{ marginLeft: "-2px" }}
                            />
                          ) : (
                            quantity
                          )}
                        </div>

                        <div
                          className="product_add_btn"
                          onClick={(e) => addQuantity(e, false)}
                        >
                          <ImPlus />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Row>
            {maxQtyError ? (
              <Row>
                <Col className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                  {" "}
                  Maximum Order Quantity is {medData.maxOrderQuantity}{" "}
                </Col>
              </Row>
            ) : (
              maxStockError && (
                <Row>
                  <Col className="max_qty_error text_right color_warn font_ubuntu font_weight_md">
                    {" "}
                    Maximum Order Quantity is {medData.availableQuantity}{" "}
                  </Col>
                </Row>
              )
            )}

            {minQtyError && (
              <Row>
                <Col className=" text_right color_warn font_ubuntu font_weight_md">
                  {" "}
                  Minimum Order Quantity is {medData.minOrderQuantity}{" "}
                </Col>
              </Row>
            )}
            {/* <Row>
               <div className="display_flex">
               <div style={{marginLeft:'70%'}}>
               <Button type="button" className="primary_btn product_add_btn font_ubuntu font_weight_md ">+ ADD</Button>

               </div>

               </div>
            </Row> */}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default React.memo(ProductCard2);
