import axios from "axios";
import { variables } from "../environments";
import qs from "qs";

let config = {
  headers: {
    "Content-Type": "application/json",
    "source":"pharmacyWeb",
    appVersionCode: variables.appVersionCode,
  },
};
const BASE = variables.ADMIN_URL;

const ADMIN_URL = variables.ADMIN_URL;
const getToken = async () => {
    let token;
    let authData = localStorage.getItem("userAuth");
    let onBehalfRetCode = localStorage.getItem("onBehalfRetCode");
    let userData = localStorage.getItem("userData");
    userData = JSON.parse(userData);
    if (authData && JSON.parse(authData).data.jwtToken) {
      token = JSON.parse(authData).data.jwtToken;
    }
    config.headers.Authorization = "Bearer " + token;
    if (
      onBehalfRetCode &&
      onBehalfRetCode.length > 0 &&
      onBehalfRetCode !== "undefined" &&
      userData.admin
    ) {
      config.headers.adminRetailerCode = onBehalfRetCode;
      config.headers.source="pharmacyWebOnbehalf"
    }
    return true;
  };


  export const getUserLedgerBalanceDetails = async (params) => {
    const data = getToken().then(() => {
      let headers = JSON.parse(JSON.stringify(config.headers))
      headers.locale = "en";
      return axios.get(`${BASE}/home/ledger/showRetailerLedgerBalanceAmount`, {
        params: params,
        headers: headers,
      });
    });
    return data;
  };

  export const getLedgerRecords = async (body) => {
    const data = getToken().then(() => {
      let headers = JSON.parse(JSON.stringify(config.headers))
      headers.locale = "en";
      return axios.post(`${BASE}/home/ledger/showLedgerRecordsForRetailer`,body, {
        params: {},
        headers: headers,
      });
    });
    return data;
  };

  export const getLedgerPaymentViews = async (params) => {
    const data = getToken().then(() => {
      let headers = JSON.parse(JSON.stringify(config.headers))
      headers.locale = "en";
      return axios.get(`${BASE}/home/ledger/ledgerPaymentMappingView`, {
        params: params,
        headers: headers,
      });
    });
    return data;
  };

  export const getPastOrderData = async (params,body) => {
    const data = getToken().then(() => {
      let headers = JSON.parse(JSON.stringify(config.headers))
      headers.locale = "en";
      return axios.post(`${BASE}/home/ledger/ledgerPendingPastOrderView`,body, {
        params: params,
        headers: headers,
      });
    });
    return data;
  };


  export const getOverDueOrderIds = async (params) => {
    const data = getToken().then(() => {
      let headers = JSON.parse(JSON.stringify(config.headers))
      headers.locale = "en";
      return axios.get(`${BASE}/credit/user/getOverDueCreditInvoice`, {
        params: params,
        headers: headers,
      });
    });
    return data;
  };
  