import { Modal } from "react-bootstrap";
import React, {useEffect } from "react";
import "../../App.css";
import "./dialog.css";
import { Common_Event_Hit } from "../../Actions/Events";
import ContactBanner from '../../Images/jpg/Digital_Payment.jpg'
import { useNavigate } from "react-router";

const LandingBanner = (props) => {
let navigate=useNavigate()

const bannerClick=()=>{
  navigate('/account/yourOrders?type=past')
  // Common_Event_Hit("DYNAMIC_EVENT", {
  //   eventName: "digital_payment_banner_click",
  // });
}
  useEffect(() => {
    // Common_Event_Hit("DYNAMIC_EVENT", {
    //   eventName: "dialog_open",
    //   dialog_name: "digital_payment_popup",
    // });
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="contact_modal"
    >
      <div className="contact_modal_body" onClick={bannerClick}>
          <img src={ContactBanner} alt="Digital Payments UPI"/>
      </div>
    </Modal>
  );
};

export default LandingBanner;
