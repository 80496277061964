import React, { Fragment, useState, useEffect, useContext } from "react";
import "../Header/Header.css";
import Logo from "../../Images/svg/logo.svg";
import Name from "../../Images/svg/brandName.svg";
import {
  Row,
  Col,
  Container,
  Tooltip,
  Popover,
  Dropdown,
  OverlayTrigger,
} from "react-bootstrap";
import "../../App.css";
import { BiChevronDown, BiChevronUp, BiCategory } from "react-icons/bi";
import { GiSevenPointedStar } from "react-icons/gi";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { SiWechat } from "react-icons/si";
import { IoMdCart, IoIosWifi } from "react-icons/io";
import { IoStorefront, IoStorefrontOutline } from "react-icons/io5";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { AiFillSetting } from "react-icons/ai";
import {
  cartDataContext,
  searchContext,
  networkStatusContext,
} from "../../App";
import { AiOutlineHome, AiFillHome } from "react-icons/ai";
import { MdLocalOffer, MdOutlineLocalOffer } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import { RiUpload2Fill } from "react-icons/ri";
import OnBehalf from "../Modals/OnBehalf";
import { Common_Event_Hit } from "../../Actions/Events";
import ErrorModal from "../Modals/ErrorModal";
import UploadToCartModal from "../Modals/UploadToCartModal";
import SearchBarComponent from "./SearchBarComponent";
import { useServiceWorker } from "../../useServiceWorker";
import cx from "classnames";
import NotificationsComponent from "./NotificationsComponent";
import { FiChevronRight } from "react-icons/fi";
import wtsp_qr from "../../Images/svg/Saveo_Whatsapp.svg";
import whatspp_logo from "../../Images/svg/wtsapp_logo.svg";

const Header = ({ userLoggedIn, categories }) => {
  const [showCategories, setShowCategories] = useState(false);
  const [showOffers, setShowOffers] = useState(false);
  const [showHelp, setShowHelp] = useState(false); //change this back to false
  const [searchString, setSearchString] = useState("");
  const [cartShake, setCartShake] = useState(false);
  const [userData, setUserData] = useState();
  const { cartData, setCartData } = useContext(cartDataContext);
  const [showHeader, setShowHeader] = useState(true);
  const [currentRoute, setCurrentRoute] = useState("");
  const [onBehalf, setShowOnBehalf] = useState(false);
  const [onBehalfExist, setOnBehalfExist] = useState(false);
  const { showRecent, setShowRecent } = useContext(searchContext);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const [uploadToCart, setUploadToCart] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, content: "" });
  let navigate = useNavigate();
  let location = useLocation();
  const { networkStatus, showNetworkStatus } = useContext(networkStatusContext);

  const handleUploadModal = () => {
    setUploadToCart(!uploadToCart);
    document.body.className = "overflow-hidden";
  };

  useEffect(() => {
    let userData = localStorage.getItem("userData");
    if (userData && userData.length) {
      setUserData(JSON.parse(userData));

      let retCode = localStorage.getItem("onBehalfRetCode");
      JSON.parse(userData)?.enabled &&
        retCode &&
        retCode.length > 0 &&
        retCode !== "undefined" &&
        setOnBehalfExist(true);
    } else {
      setOnBehalfExist(false);
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (cartData && cartData.size) {
      setCartShake(true);
      setTimeout(() => {
        setCartShake(false);
      }, [1500]);
    }
  }, [cartData]);

  useEffect(() => {
    if (location.pathname.split("/")[1] !== "search") {
      setSearchString("");
    }

    if (location.pathname.split("/")[1] === "home") {
      setCurrentRoute("home");
    } else if (location.pathname.split("/")[1] === "categories") {
      setCurrentRoute("categories");
    } else if (location.pathname.split("/")[1] === "store") {
      setCurrentRoute("store");
    } else {
      setCurrentRoute("");
    }

    if (location.pathname.split("/")[2] === "specialOffers") {
      setCurrentRoute("offers");
    }

    if (location.pathname.split("/")[1] === "search") {
      // setShowRecent(false)
      let string = location.pathname.split("/")[2].split("%20").join(" ");
      setSearchString(string);
    } else if (
      location.pathname.split("/")[1] === "onboarding" ||
      location.pathname.split("/")[1] === "login" ||
      location.pathname.split("/")[1] === "wholesale"
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location, userLoggedIn]);

  return (
    <>
      <ErrorModal
        show={errorModal.show}
        content={errorModal.content}
        apiData={errorModal?.apiData}
        tryAgain={() => {
          errorModal?.tryAgain();
          setErrorModal({ show: false, content: "" });
        }}
        onHide={() => {
          setErrorModal({ show: false, content: "" });
        }}
      />

      {/* Internet StatusBar */}
      {!networkStatus ? (
        <div className="network-panel">
          <p className="no-network">
            <BsFillExclamationTriangleFill /> No Internet Connection
          </p>
        </div>
      ) : showNetworkStatus ? (
        <div className="network-panel">
          <p className="network">
            <IoIosWifi /> Internet Connection is back online
          </p>
        </div>
      ) : isUpdateAvailable ? (
        isUpdateAvailable && (
          <div className="update-available-banner flex-align-center-row">
            <p>
              New update is available.&nbsp;&nbsp;
              <span
                style={{ marginRight: "0.4rem" }}
                onClick={() => updateAssets()}
              >
                click to update
              </span>
              <AiFillSetting
                className="settingIcon"
                size="1rem"
                color="#4b4495"
              />
            </p>
          </div>
        )
      ) : (
        showHeader && (
          <a
            className="contact_snackbar flex-align-center-row"
            href="https://api.whatsapp.com/send?phone=8390088888"
            target="_blank"
            onClick={() =>
              Common_Event_Hit("DYNAMIC_EVENT", {
                eventName: "whatsapp_scrollbar_tap",
              })
            }
          >
            <div className="marquee">
              <div>
                <span className="firstSpan">
                  <span className="firstSpan_innerDiv">
                    Say <b>'Hi'</b> on Whats App:{" "}
                    <img
                      src={whatspp_logo}
                      className="menu_wtsapp_mini"
                      alt="WhatsApp"
                    />{" "}
                    <b>83900 88888</b>
                  </span>
                  <span className="firstSpan_innerDiv2">
                    <b className="text_underline">Click here</b>
                    &nbsp; to connect with support team{" "}
                  </span>
                </span>

                <span>
                  <span className="secondSpan_innerDiv">
                    Say <b>'Hi'</b> on Whats App:{" "}
                    <img
                      src={whatspp_logo}
                      className="menu_wtsapp_mini"
                      alt="WhatsApp"
                    />{" "}
                    <b>83900 88888</b>
                  </span>
                  <span className="secondSpan_innerDiv2">
                    <b className="text_underline">Click here</b>
                    &nbsp; to connect with support team{" "}
                  </span>
                </span>
              </div>
            </div>
          </a>
        )
      )}

      <header
        className={cx(
          { header_outer_div: !onBehalfExist },
          { header_outer_div_ob: onBehalfExist },
          { "bg-hidden": !userData || !showHeader }
        )}
        style={{ position: "fixed", top: "28px" }}
      >
        <OnBehalf
          show={onBehalf}
          content={"Add Retailer Code"}
          onHide={() => {
            setShowOnBehalf(false);
          }}
        />
        {/* mobile footer  */}
        <div className="mobile_footer">
          <div className="display_flex space_around font_ubuntu font_weight_md font_16 color_primary">
            <div
              className="text_center footer_sec"
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "nav_tap",
                  navItem: "home",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                navigate("/home");
              }}
            >
              {currentRoute === "home" ? (
                <span className="color_secondary">
                  <AiFillHome className="footer_icons" /> <br></br>
                  Home
                </span>
              ) : (
                <>
                  <AiOutlineHome className="footer_icons" /> <br></br>
                  Home
                </>
              )}
            </div>
            <div
              className="text_center footer_sec"
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "nav_tap",
                  navItem: "offers",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                navigate("/categories/specialOffers");
              }}
            >
              {currentRoute === "offers" ? (
                <span className="color_secondary">
                  <MdLocalOffer className="footer_icons" /> <br></br>
                  Offers
                </span>
              ) : (
                <>
                  <MdOutlineLocalOffer className="footer_icons" /> <br></br>
                  Offers
                </>
              )}
            </div>
            <div
              className="text_center footer_sec"
              onClick={async () => {
                Common_Event_Hit("DYNAMIC_EVENT", {
                  eventName: "nav_tap",
                  navItem: "account",
                  screen_name: localStorage.getItem("currentScreen"),
                });
                navigate("/store");
              }}
            >
              {currentRoute === "store" ? (
                <span className="color_secondary">
                  {userData?.onlinePaymentEnabled && (
                    <div className="target_dot_footer"></div>
                  )}
                  <IoStorefront className="footer_icons" /> <br></br>
                  Store
                </span>
              ) : (
                <>
                  {userData?.onlinePaymentEnabled && (
                    <div className="target_dot_footer"></div>
                  )}
                  <IoStorefrontOutline className="footer_icons" /> <br></br>
                  Store
                </>
              )}
            </div>
          </div>
        </div>

        {/* web header  */}
        <Container
          fluid
          className={cx(
            "header",
            "font_ubuntu",
            "pos_fixed",
            { bg_behalf: onBehalfExist },
            { header_outer_div: showRecent === true && !onBehalfExist },
            { header_outer_div_ob: showRecent === true && onBehalfExist }
          )}
          // style={{
          //   backgroundColor:
          //     location.pathname.split("/")[1] === "search"
          //       ? "#2f2963"
          //       : "#4B4495",
          // }}
        >
          <Row
            className="row flex-align-center-row"
            style={{ alignItems: "center" }}
          >
            <Col className="web_menu" style={{ maxWidth: "250px" }}>
              <Row>
                <Col md={7}>
                  <Link
                    to="/home"
                    onClick={() => {
                      document.body.className = "";
                      setShowRecent(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        marginTop: "-5px",
                      }}
                    >
                      <img src={Logo} className="saveo_logo" alt="saveo logo" />{" "}
                      <img src={Name} className="saveo_name" alt="saveo name" />
                    </div>{" "}
                  </Link>
                </Col>
              </Row>
            </Col>

            <Col className="mob_menu padding_top_12">
              <Row>
                <Col className="flex-start-center-row" style={{ flex: "1" }}>
                  {" "}
                  <Link
                    to="/home"
                    onClick={() => {
                      document.body.className = "";
                      setShowRecent(false);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={Logo} className="saveo_logo" alt="saveo logo" />{" "}
                      <img src={Name} className="saveo_name" alt="saveo name" />
                    </div>{" "}
                  </Link>
                </Col>

                {userData && userData?.enabled && (
                  <>
                    {userData?.admin || userData?.salesPerson ? (
                      <Col className="cart_icon_cntr menu_icons_min cart_icon_min">
                        <span onClick={() => setShowOnBehalf(true)}>
                          {" "}
                          {onBehalfExist ? (
                            <FaUserShield className="color_white  cursor_pointer" />
                          ) : (
                            <FaUserShield className="color_white cursor_pointer" />
                          )}
                        </span>
                      </Col>
                    ) : (
                      ""
                    )}
                  </>
                )}

                <Col className="cart_icon_cntr menu_icons_min cart_icon_min">
                  {/* help & support */}
                  <Fragment>
                    {" "}
                    <OverlayTrigger
                      trigger="hover"
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Popover id={`popover-positioned-bottom`}>
                          <Popover.Header as="h3">
                            <div
                              className="report-issue-container"
                              onClick={() =>
                                navigate("/help-support/ReportIssue")
                              }
                            >
                              <Link to="/help-support/ReportIssue">
                                Report Issue
                              </Link>
                            </div>
                          </Popover.Header>
                          <Popover.Body>
                            <div
                              className="q-and-a-container"
                              onClick={() => navigate("/help-support/QandA")}
                            >
                              <Link to="/help-support/QandA">
                                Question and Answers
                              </Link>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div
                        onClick={() =>
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "support_icon_tap",
                          })
                        }
                      >
                        <SiWechat className="color_white cursor_pointer" />{" "}
                      </div>
                    </OverlayTrigger>
                  </Fragment>
                </Col>
                {/* <Col className=" menu_icons_min">
                {" "}
                <Fragment>
                  {" "}
                  <MdNotifications className="color_white  cursor_pointer" />{" "}
                </Fragment>{" "}
              </Col> */}
                <Col
                  className={cx(
                    { cart_empty: !cartData || cartData?.size < 1 },
                    "resp_cart",
                    "menu_icons_min",
                    "text_center",
                    "cart_icon_min",
                    "cursor_pointer"
                  )}
                  onClick={async () => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "cart_icon_tap",
                      screen_name: localStorage.getItem("currentScreen"),
                    });
                    navigate("/cart");
                  }}
                  style={{
                    borderColor: onBehalfExist ? "transparent" : "#3d377ec2",
                  }}
                >
                  {" "}
                  <Fragment>
                    {" "}
                    <div className={cartShake && "errorAnime"}>
                      {cartData && cartData.size > 0 && (
                        <div className="cart_notification font_ubuntu">
                          {cartData.size}
                        </div>
                      )}
                      {/* {
                        cartShake &&  <img src={Celebrate} style={{width:'40px'}}/>
                      } */}
                      <IoMdCart />{" "}
                    </div>
                  </Fragment>{" "}
                </Col>
                <Col
                  className="  cart_icon_cntr m_r_16 menu_icons_min cart_icon_min"
                  style={{ borderColor: onBehalfExist ? "white" : "#3d377ec2" }}
                >
                  <NotificationsComponent userLoggedIn={userLoggedIn} />
                </Col>
              </Row>
            </Col>

            <Col lg={6} className="searchHeader">
              <div className="searchBarDiv">
                <SearchBarComponent headerType={"RETAIL"} />
              </div>
            </Col>

            {/* <Col className="dummy_div padding_top_12 menu_icons_max"></Col> */}

            {/* {onBehalfExist && ( */}
            <Col className="menu_icons_max text_center icons_div">
              <div
                style={{
                  borderColor: onBehalfExist ? "transparent" : "#3d377ec2",
                }}
              >
                <button
                  className="upload_cart_modal_btn"
                  onClick={handleUploadModal}
                >
                  Upload &nbsp;
                  <RiUpload2Fill
                    className="header_icons cursor_pointer"
                    style={{ fontSize: "1.1rem" }}
                  />
                </button>
              </div>
              {userData &&
                userData?.enabled &&
                (userData?.admin || userData?.salesPerson) && (
                  <div
                    style={{
                      padding: "2px 5px",
                      height: "35px",
                      borderColor: onBehalfExist ? "white" : "#3d377ec2",
                    }}
                  >
                    {userData && userData?.enabled && (
                      <>
                        {userData?.admin || userData?.salesPerson ? (
                          <div onClick={() => setShowOnBehalf(true)}>
                            {" "}
                            <OverlayTrigger
                              key={"bottom"}
                              placement={"bottom"}
                              overlay={
                                <Tooltip
                                  id={`tooltip-bottom`}
                                  style={{ marginTop: "0px" }}
                                >
                                  On Behalf
                                </Tooltip>
                              }
                            >
                              <div>
                                {onBehalfExist ? (
                                  <FaUserShield className="color_white header_icons cursor_pointer" />
                                ) : (
                                  <FaUserShield className="color_white header_icons cursor_pointer" />
                                )}
                              </div>
                            </OverlayTrigger>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                )}

              <div
                style={{
                  padding: "3px 5px",
                  height: "35px",
                  borderColor: onBehalfExist ? "white" : "#3d377ec2",
                }}
              >
                <OverlayTrigger
                  key={"bottom"}
                  placement={"bottom"}
                  overlay={
                    <Tooltip id={`tooltip-bottom`}>Notifications</Tooltip>
                  }
                >
                  <NotificationsComponent userLoggedIn={userLoggedIn} />
                </OverlayTrigger>
              </div>

              <div
                className={cx(
                  { cart_empty_max: !cartData || cartData?.size < 1 },
                  "cart_icon",
                  "menu_icons_max",
                  "text_center",
                  "text_center",
                  "cursor_pointer"
                )}
                onClick={async () => {
                  Common_Event_Hit("DYNAMIC_EVENT", {
                    eventName: "cart_icon_tap",
                    screen_name: localStorage.getItem("currentScreen"),
                  });
                  navigate("/cart");
                }}
                style={{
                  border: onBehalfExist ? "none" : "1px solid #3d377ec2",
                }}
              >
                {" "}
                <Fragment>
                  {" "}
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        style={{ marginTop: "0px" }}
                      >
                        Cart
                      </Tooltip>
                    }
                  >
                    <div
                      id="cartIcon"
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "cart_icon_tap",
                          screen_name: localStorage.getItem("currentScreen"),
                        });
                        navigate("/cart");
                      }}
                      style={{
                        border: onBehalfExist ? "none" : "1px solid #3D377E",
                      }}
                    >
                      {cartData && cartData.size > 0 && (
                        <div className="cart_notification font_ubuntu">
                          {cartData.size}
                        </div>
                      )}
                      {/* {
                        cartShake &&  <img src={Celebrate} style={{width:'40px'}}/>
                      } */}
                      <IoMdCart style={{ fontSize: "18px" }} />{" "}
                    </div>
                  </OverlayTrigger>
                </Fragment>{" "}
              </div>

              <div
                className="menu_icons_max text_center"
                style={{
                  padding: "2px 5px",
                  height: "35px",
                  borderColor: onBehalfExist ? "white" : "#3d377ec2",
                }}
              >
                {" "}
                <Fragment>
                  {" "}
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"bottom"}
                    overlay={
                      <Tooltip
                        id={`tooltip-bottom`}
                        style={{ marginTop: "0px" }}
                      >
                        Store
                      </Tooltip>
                    }
                  >
                    <div>
                      {userData?.onlinePaymentEnabled && (
                        <div className="target_dot"></div>
                      )}
                      <IoStorefront
                        onClick={async () => {
                          Common_Event_Hit("DYNAMIC_EVENT", {
                            eventName: "nav_tap",
                            navItem: "account",
                            screen_name: localStorage.getItem("currentScreen"),
                          });
                          navigate("/store");
                        }}
                        className="color_white header_icons cursor_pointer"
                      />{" "}
                    </div>
                  </OverlayTrigger>
                </Fragment>{" "}
              </div>
            </Col>

            {/* )} */}

            {/* <Col className="menu_icons_max text_center"></Col> */}

            {/* notification icon large screen */}
            {/* <Col className="menu_icons_max text_center">
              
            </Col> */}
          </Row>
        </Container>

        {uploadToCart ? (
          <UploadToCartModal
            onBehalfExist={onBehalfExist}
            uploadToCart={uploadToCart}
            setUploadToCart={setUploadToCart}
            userData={userData}
          />
        ) : (
          <></>
        )}

        {!showRecent && (
          <>
            <div className="dup_div"></div>
            <Container
              fluid
              className={
                onBehalfExist
                  ? "header header_2 shadow font_ubuntu sub_menu bg_behalf"
                  : "header header_2 shadow font_ubuntu sub_menu"
              }
            >
              <div className="menu_bar shadow_btm flex-align-center-row">
                <div
                  className="cursor_pointer font_14"
                  onClick={async () => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "nav_tap",
                      navItem: "home",
                      screen_name: localStorage.getItem("currentScreen"),
                    });
                    navigate("/home");
                  }}
                >
                  {" "}
                  {onBehalfExist ? (
                    <span
                      className={
                        currentRoute === "home" && "color_primary p-8-1"
                      }
                    >
                      <AiFillHome />
                      Home
                    </span>
                  ) : (
                    <span
                      className={
                        currentRoute === "home" &&
                        "color_tertiory active_nav p-8-1"
                      }
                    >
                      <AiFillHome />
                      Home
                    </span>
                  )}
                </div>
                <div
                  className="cursor_pointer font_14"
                  onMouseEnter={() => setShowCategories(true)}
                  onMouseLeave={() => setShowCategories(false)}
                >
                  {onBehalfExist ? (
                    <span
                      className={
                        currentRoute === "categories" && "color_primary p-8-1"
                      }
                    >
                      <BiCategory />
                      Categories
                    </span>
                  ) : (
                    <span
                      className={
                        currentRoute === "categories" &&
                        "color_tertiory active_nav p-8-1"
                      }
                    >
                      <BiCategory />
                      Categories
                    </span>
                  )}{" "}
                  {!showCategories && <BiChevronDown />}{" "}
                  {showCategories && <BiChevronUp />}
                  <Dropdown.Menu className="padding_10" show={showCategories}>
                    {/* Silvercross dropdown category */}
                    <Dropdown.Item
                      onClick={async () => {
                        Common_Event_Hit("DYNAMIC_EVENT", {
                          eventName: "nav_tap",
                          navItem: "silvercross",
                          screen_name:
                            localStorage.getItem("currentScreen"),
                        });
                        navigate("/silvercross");
                      }}
                      style={{color: "#592C82"}}
                    >
                      SilverCross
                    </Dropdown.Item>

                    {categories &&
                      categories.map((cat, i) => {
                        if (cat.active) {
                          if (cat.section_name === "500+ Companies") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/companies");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Special Offers") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/specialOffers");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Regular Schemes") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/regularSchemes");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Corona Essentials") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/coronaEssentials");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Surgical") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Surgicals");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Generic") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/generic");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Ayurvedic") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Ayurvedic");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Speciality") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Speciality");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "Allopathy") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/Allopathy");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (
                            cat.section_name === "Factory to Pharmacy"
                          ) {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/factorytopharmacy");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          } else if (cat.section_name === "OTC") {
                            return (
                              <Dropdown.Item
                                onClick={async () => {
                                  Common_Event_Hit("DYNAMIC_EVENT", {
                                    eventName: "nav_tap",
                                    navItem: cat.iconName,
                                    screen_name:
                                      localStorage.getItem("currentScreen"),
                                  });
                                  navigate("/categories/OTC");
                                }}
                                key={i}
                                eventKey={i}
                              >
                                {cat.section_name}
                              </Dropdown.Item>
                            );
                          }
                        }
                      })}
                  </Dropdown.Menu>
                </div>
                <div
                  className="cursor_pointer font_14"
                  onMouseEnter={() => setShowOffers(true)}
                  onMouseLeave={() => setShowOffers(false)}
                  onClick={async () => {
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "nav_tap",
                      navItem: "offers",
                      screen_name: localStorage.getItem("currentScreen"),
                    });
                    navigate("/categories/specialOffers");
                  }}
                >
                  {onBehalfExist ? (
                    <span
                      className={
                        currentRoute === "offers" && "color_primary p-8-1"
                      }
                    >
                      <GiSevenPointedStar />
                      Offers
                    </span>
                  ) : (
                    <span
                      className={
                        currentRoute === "offers" &&
                        "color_tertiory active_nav p-8-1"
                      }
                    >
                      <GiSevenPointedStar />
                      Offers
                    </span>
                  )}

                  {/* {!showOffers && <BiChevronDown/>} {showOffers && <BiChevronUp/>}  */}
                  {/* <Dropdown.Menu className="padding_10" show={showOffers}>
       <Dropdown.Item eventKey="1">Deal of the day</Dropdown.Item>
       <Dropdown.Item eventKey="2">Flash Sale</Dropdown.Item>
       <Dropdown.Item eventKey="3">Special Offers</Dropdown.Item>
       <Dropdown.Item eventKey="4">Bulk Rate</Dropdown.Item>
       </Dropdown.Menu> */}
                </div>
                <section
                  className="color_white font_weight_md cursor_pointer font_14 no_wrap chat_header_menu"
                  onMouseEnter={() => setShowHelp(true)}
                  onMouseLeave={() => setShowHelp(false)}
                  onClick={() =>
                    Common_Event_Hit("DYNAMIC_EVENT", {
                      eventName: "whatsapp_icon_tap",
                    })
                  }
                >
                  <a
                    style={{ textDecoration: "none", color: "#075E54" }}
                    href="https://api.whatsapp.com/send?phone=8390088888"
                    target="_blank"
                  >
                    <img
                      src={whatspp_logo}
                      className="menu_wtsapp"
                      alt="WhatsApp"
                    />{" "}
                    Chat with us
                    <Dropdown.Menu
                      className="padding_10 chat_dropdown"
                      show={showHelp}
                    >
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://api.whatsapp.com/send?phone=8390088888"
                        target="_blank"
                      >
                        <div className="qr_nmbr">83900 88888</div>
                        <img
                          className="wtsapp_qr"
                          src={wtsp_qr}
                          alt="whatsapp qr"
                        />
                        <div className="qr_redirect_btn">
                          {" "}
                          {/* <BsWhatsapp
                          style={{
                            marginTop: "-3px",
                            fontWeight: "bolder",
                            fontSize: "15px",
                          }}
                        />{" "} */}
                          <img
                            src={whatspp_logo}
                            className="menu_wtsapp"
                            alt="WhatsApp"
                          />
                          <span style={{ fontWeight: "normal" }}>
                            &nbsp;&nbsp;Say
                          </span>{" "}
                          'Hi' <FiChevronRight />
                        </div>
                      </a>
                    </Dropdown.Menu>
                  </a>
                </section>
              </div>
            </Container>
          </>
        )}

        <div className="header_backLayer"></div>
      </header>
    </>
  );
};

export default React.memo(Header);
